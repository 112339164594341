import { makeStyles } from '@material-ui/core/styles'

const FrmProductStyle = makeStyles((themes) => ({
  root: {
    flexGrow: 1,
    paddingTop: "5px",
    paddingBottom: "10vh",
    background: "#f5f5f5",
    minHeight: '100vh'
  },
  rowMain: {
    minHeight: 'calc(100vh-65px)',
    padding: '1rem 0.5rem',
    marginBottom: '4rem',
  },
  boxHeader: {
    background: 'rgba(51, 76, 26, 0.9)',
    borderRadius: '5px 5px 0px 0px',
    padding: '10px',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    '& h6': {
      letterSpacing: '1.5px',
    }
  },
  btnHeader: {
    textAlign: 'right',
    '& > *': {
      marginRight: '1rem',
      '& > *': {
        color: '#fff',
      }
    }
  },
  listItem: {
  },
  listRow: {
    color: 'rgba(51, 76, 26, 1)',
    fontWeight: 800,
    justify: 'center',
    alignItems: 'center',
    borderBottom: '1px solid rgba(51,76,26,0.8)',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(51,76,26,0.3)',
    },
    '& div': {
      padding: '8px',
    }
  },
  frmProd: {
  	background: "#fff",
  	padding: themes.spacing(1),
    paddingBottom: themes.spacing(2),
    marginBottom: themes.spacing(1),
    borderRadius: "0px 0px 10px 10px",
  },
  boxFrm: {
    boxShadow: "5px 8px 20px rgba(51,76,26,0.5)",
  },
  boxAction: {
    alignItems: 'space-between',
    '& > *': {
      marginLeft: themes.spacing(1)
    }
  },
  btnEye: {
    color: 'rgba(51, 76, 26, 1)'
  },
  btnEdit: {
    color: 'rgba(51, 76, 26, 1)'
  },
  btnDelete: {
    color: 'rgba(51, 76, 26, 1)'
  },
  rowMenu: {
    position: 'fixed',
    bottom: 0,
  },
  groupInp: {
  	marginTop: '10px',
  },
  inp: {
  	marginTop: '15px',
  },
  imgProd: {
  	width: '305px',
  	height: '305px',
  	border: '1px solid #000',
  	cursor: 'pointer',
  	'&:active': {
  		width: '280px',
  		height: '280px',
      border: '1px solid #ccc',
      opacity: '0.5',
  	}
  },
  inputFile: {
  	display: 'none',
  },
  btnUpload: {
  	marginTop: '5px',
  },
  formControl: {
    margin: themes.spacing(1),
    marginTop: themes.spacing(2),
    minWidth: '100%',
  },
  btnFrm: {
    height: '10vh',
    color: '#f2f2f2',
    background: 'rgba(34, 51, 15, 1)',
    letterSpacing: '1px',
    '&:hover': {
      color: 'rgba(34, 51, 15, 1)',
      background: 'none',
      border: '1px solid rgba(34, 51, 15, 0.8)'
    },
    padding: themes.spacing(2),
  },
  groupBtn: {
    marginTop: themes.spacing(1),
  }
}))

export default FrmProductStyle