import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import TagFaces from '@material-ui/icons/TagFaces'
import ViewList from '@material-ui/icons/ViewList'
import More from '@material-ui/icons/More'
import Style from '@material-ui/icons/Style'
import Money from '@material-ui/icons/Money'
import SquareFoot from '@material-ui/icons/SquareFoot'


const useStyles = makeStyles((themes) => ({
  bottomMenu: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    justifyContent: 'space-between',
    background: 'rgba(51, 76, 26, 1)'
  },
  btnBarMenu: {
    color: 'rgba(255, 255, 255, 0.6)',
    '&:hover': {
      color: '#fff',
    },
    '&.Mui-selected': {
      color: '#fff'
    }
  }
}))

const ProductFooterBar = props => {
  const [link, setLink] = useState('')

  const handleClick = link => {
    setLink(link)
  }

  const classes = useStyles()

  return (
    <>
      { link ? <Redirect to={link} /> : null}
      <BottomNavigation
        value={props.active}
        showLabels
        className={classes.bottomMenu}>
        <BottomNavigationAction 
          onClick={e => handleClick('/product')}
          className={classes.btnBarMenu} 
          label="Produtos" 
          icon={<ViewList />} />
        <BottomNavigationAction 
          onClick={e => handleClick('/services')}
          className={classes.btnBarMenu} 
          label="Serviços" 
          icon={<More />} />
        <BottomNavigationAction 
          onClick={e => handleClick('/category')}
          className={classes.btnBarMenu} 
          label="Categorias" 
          icon={<Style />} />
        <BottomNavigationAction
          onClick={e => handleClick('/brand')} 
          className={classes.btnBarMenu} 
          label="Marcas" 
          icon={<Money />} />
        <BottomNavigationAction
          onClick={e => handleClick('/unity')} 
          className={classes.btnBarMenu} 
          label="Unidades" 
          icon={<SquareFoot />} />
      </BottomNavigation>
    </>
  )
}

export default ProductFooterBar