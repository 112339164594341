import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import ToggleOn from '@material-ui/icons/ToggleOn'
import AddCircle from '@material-ui/icons/AddCircle'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import Search from '@material-ui/icons/Search'
import BarMenu from '../BarMenu'
import AlertSnack from '../tools/AlertSnack'
import Loading from '../tools/Loading'
import ProductFooterBar from '../tools/ProductFooterBar'
import ProductBoardStyle from './style'

const ProductBoard = () => {

  const [openAlert, setOpenAlert] = useState(false)
  const [msgAlert, setMsgAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('success')
  const [loadRow, setLoadRow] = useState(true)
  const [openLoading, setOpenLoading] = useState(true)

  //Filter product
  const [disabledProduct, setDisabledProduct] = useState(false)
  const [status, setStatus] = useState(true)
  const [filBarCode, setFilBarCode] = useState()
  const [filName, setFilName] = useState()

  const [listItens, setListItens] = useState()
  
  const classes = ProductBoardStyle()

  useEffect(() => {
    const listProduct = async () => {
      try {
        const listRows = await api.get('/product')
        if(listRows.status === 200) {
          setListItens(listRows.data)
          setOpenLoading(false)
        } else {
          setOpenLoading(false)
          setMsgAlert('Não a produtos cadastrados.')
          setTypeAlert('info')
          setOpenAlert(true)
        }
      } catch (err) {
        setOpenLoading(false)
        setMsgAlert('Erro ao carregar lista produtos.')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    }
    listProduct()
    setLoadRow(false)
  }, [loadRow])

  const handleRemove = async (e, id) => {
    e.preventDefault()
    setOpenLoading(true)
    const res = await api.delete(`/product/${id}`)
    console.log(res)
    if (res.status === 201) {
      setOpenLoading(false)
      setMsgAlert('Não é possivel desativar o produto com estoque ativo.')
      setTypeAlert('info')
      setOpenAlert(true)
    } else if (res.status === 200) {
      setOpenLoading(false)
      setMsgAlert('Produto desativado com sucesso.')
      setTypeAlert('success')
      setOpenAlert(true)
      setLoadRow(true)
    } else {
      setOpenLoading(false)
      setMsgAlert('Erro comunicar com o servidor.')
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  const handleFilter = async e => {
    e.preventDefault()
    setOpenLoading(true)
    try {
      const filter = {
        barCode: filBarCode,
        name: filName,
        status: status
      }
      const listProd = await api.post('/product/fetch', filter)
      console.log(listProd)
      setListItens(listProd.data)
      setOpenLoading(false)
    } catch (err) {
      setOpenLoading(false)
      setMsgAlert("Erro ao comunicar com o servidor.")
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  const handleActive = async id => {
    setOpenLoading(true)
    try {
      const activeProd = await api.put(`/product/active/${id}`)
      setStatus(true)
      setOpenLoading(false)
      setLoadRow(true)
    } catch (err) {
      setOpenLoading(false)
      setMsgAlert("Erro ao comunicar com o servidor.")
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  return (
    <>
    <BarMenu />
    <Grid container="fluid" className={classes.root} lg={12} xs={12}>

      {openAlert ? <AlertSnack 
          open={openAlert} 
          type={typeAlert} 
          msg={msgAlert} 
          setSnack={setOpenAlert} /> : null}
      
      {/** Box filter product */}
      <Grid item lg={12} xs={12}>
          <form 
            className={classes.frmFluid}
            onSubmit={e => handleFilter(e)}>
            <Grid 
              container="fluid" 
              justify="space-around"
              lg={12} 
              xs={12} 
              className={classes.boxFilter}>

              <Grid item lg={2} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Cód. Barras"
                  name="bar_code"
                  value={filBarCode}
                  onChange={e => setFilBarCode(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Produto"
                  name="name"
                  value={filName}
                  onChange={e => setFilName(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel shrink htmlFor="sel-status">
                    Status
                  </InputLabel>
                  <Select
                    inputPros={{
                      name: 'status',
                      id: 'sel-status'
                    }}
                    variant="outlined"
                    label="Status"
                    fullWidth
                    value={status}
                    onChange={e => setStatus(e.target.value)}>
                    <MenuItem key="1" value={true}>
                      Ativo
                    </MenuItem>
                    <MenuItem key="0" value={false}>
                      Inativo
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={3} xs={12}>
               <Button
                className={classes.btnFrm}
                type="submit"
                fullWidth
                startIcon={<Search/>}>
                Buscar
               </Button>
              </Grid>
            </Grid>
          </form>
      </Grid>

      <Grid className={classes.rowMain} item lg={12} xs={12}>

        <Grid container="fluid" className={classes.boxHeader} >
          <Grid item lg={2} xs={2}>
            <Typography noWrap={true} variant="h6" component="h6"> Código </Typography>
          </Grid>
          <Grid item lg={6} xs={6}>
            <Typography noWrap={true} variant="h6" component="h6"> Produto </Typography>
          </Grid>
          <Grid item lg={2} xs={2}>
            <Typography noWrap={true} variant="h6" component="h6"> Quantidade </Typography>
          </Grid>
          <Grid item className={classes.btnHeader} lg={2} xs={2}> 
            <Link to={'/product/add'} className={classes.btnEye}>
            <IconButton>
              <AddCircle />
            </IconButton>
            </Link>
          </Grid>
        </Grid>

        <Grid container="fluid" className={classes.listItem}>
        { listItens ?
          listItens.map((prod, index) => (
            <Grid key={index} container="fluid" lg={12} xs={12} className={classes.listRow}>
              <Grid item lg={2} xs={2}>
                <Typography noWrap={true} variant="body" component="div">{prod.bar_code}</Typography>
              </Grid>
              <Grid item lg={6} xs={6}>
              <Typography noWrap={true} variant="body" component="div">{prod.name.toUpperCase()}</Typography>
              </Grid>
              <Grid item lg={2} xs={2}>
                <Typography noWrap={true} variant="body" component="div">{prod.amount}</Typography>
              </Grid>
              <Grid className={classes.boxAction} item lg={2} xs={2}>
              {prod.status ? 
                <Link to={`/product/${prod.id}`}>
                  <IconButton color="primary">
                    <Edit />
                  </IconButton>
                </Link>
                : null 
              }
              {prod.status ? 
                  <IconButton 
                    onClick={e => handleRemove(e, prod.id)} 
                    color="secondary">
                    <Delete />
                  </IconButton>
                :
                  <IconButton 
                    onClick={e => handleActive(prod.id)}>
                    <ToggleOn />
                  </IconButton>
              }
              </Grid>
            </Grid>
          )) : null
        }
        </Grid>

      </Grid>

      <Grid item className={classes.rowMenu} lg={12} xs={12}>
        <ProductFooterBar active={0} />
      </Grid>

    </Grid>

    <Loading open={openLoading} />
    </>
  )

}

export default ProductBoard