import { makeStyles } from '@material-ui/core/styles'

const ProductBoardStyle = makeStyles((themes) => ({
  root: {
    flexGrow: 1,
  },
  rowMain: {
    minHeight: 'calc(100vh-65px)',
    padding: '1rem 0.5rem',
    marginBottom: '4rem',
  },
  boxHeader: {
    background: 'rgba(51, 76, 26, 0.9)',
    borderRadius: '5px 5px 0px 0px',
    padding: '10px',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    '& h6': {
      letterSpacing: '1.5px',
    }
  },
  btnHeader: {
    textAlign: 'right',
    '& > *': {
      marginRight: '1rem',
      '& > *': {
        color: '#fff',
      }
    }
  },
  listItem: {
  },
  listRow: {
    color: 'rgba(51, 76, 26, 1)',
    fontWeight: 800,
    justify: 'center',
    alignItems: 'center',
    borderBottom: '1px solid rgba(51,76,26,0.8)',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(51,76,26,0.3)',
    },
    '& div': {
      padding: '8px',
    }
  },
  boxAction: {
    alignItems: 'space-between',
    '& > *': {
      marginLeft: themes.spacing(1)
    }
  },
  btnEye: {
    color: 'rgba(51, 76, 26, 1)'
  },
  btnEdit: {
    color: 'rgba(51, 76, 26, 1)'
  },
  btnDelete: {
    color: 'rgba(51, 76, 26, 1)'
  },
  rowMenu: {
    position: 'fixed',
    bottom: 0,
  },
  boxFilter: {
    padding: '10px',
    borderBottom: '1px solid rgba(51, 76, 26, 0.4)',
  },
  frmFluid: {
    width: '100%'
  },
  btnFrm: {
    background: "rgba(51, 76, 26, 1)",
    color: '#f3f3f3',
    height: '100%',
    '&:hover': {
      background: "rgba(51, 76, 26, 0.7)",
    }
  },
  formControl: {
    width: '100%'
  }
}))

export default ProductBoardStyle