import axios from "axios";
import { getToken } from "./auth";

const apiUpload = axios.create({
  baseURL: "https://cloudhsg.ddns.net:3333"
});

apiUpload.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.post['Content-Type'] = 'multipart/form-data';
  }
  return config;
});

export default apiUpload;