import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const makeStyle = makeStyles((themes) => ({
  backdrop: {
    zIndex: themes.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const Loading = props => {
  const classes = makeStyle()
  return (
    <Backdrop 
      className={classes.backdrop} 
      open={props.open} >
      <CircularProgress 
        color="inherit" />
    </Backdrop>
  )
}

export default Loading