import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import api from '../../services/api'
import apiUpload from '../../services/apiUpload'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import BarMenu from '../BarMenu'
import AlertSnack from '../tools/AlertSnack'
import Loading from '../tools/Loading'
import ProductFooterBar from '../tools/ProductFooterBar'
import noImg from '../../assets/no_image.png'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Save from '@material-ui/icons/Save'
import FrmProductStyle from './style'

const FrmProduct = (props) => {

  //State Product
  const [name, setName] = useState("")
  const [barCode, setBarCode] = useState("")
  const [brandId, setBrandId] = useState(0)
  const [subCategoryId, setSubCategoryId] = useState(0)
  const [categoryId, setCategoryId] = useState(0)
  const [unityId, setUnityId] = useState(0)
  const [imgProd, setImgProd] = useState("")
  const [imgProdTemp, setImgProdTemp] = useState("")
  const [prevImgProd, setPrevImgProd] = useState("")
  const [description, setDescription] = useState("")
  const [inventoryMovement, setInventoryMovement] = useState(false)
  const [amount, setAmount] = useState(0)

  //State of controller page
  const [redirect, setRedirect] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [msgAlert, setMsgAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('success')
  const [loadRow, setLoadRow] = useState(true)
  const [openLoading, setOpenLoading] = useState(false)
  const [modEdit, setModEdit] = useState(false)
  const [confImgUpload, setConfImgUpload] = useState(false)
  const [listCategory, setListCategory] = useState('')
  const [loadListCategory, setLoadListCategory] = useState(true)
  const [listSubCategory, setListSubCategory] = useState('')
  const [listBrand, setListBrand] = useState('')
  const [loadListBrand, setLoadListBrand] = useState(true)
  const [listUnity, setListUnity] = useState('')
  const [loadListUnity, setLoadListUnity] = useState(true)

  const productLoading = async product => {
    setName(product.name)
    setBarCode(product.bar_code)
    setBrandId(product.brand_id)
    setSubCategoryId(product.sub_category_id)
    setCategoryId(product.category_id)
    setUnityId(product.unity_id)
    setImgProd(product.img_prod)
    if(product.img_prod !== null){
      setPrevImgProd(`http://localhost:3333/product/view/img/${product.img_prod}`)
    } else {
      setImgProd('')
    }
    setDescription(product.description)
    setInventoryMovement(product.inventory_movement)
    setAmount(product.amount)
    const listSub = await api.get(`/product/category/sub/list/${product.category_id}`)
    if (listSub.status === 200) {
      setListSubCategory(listSub.data)
    }
  }

  //Load unities
  useEffect(() => {
    const listUnities = async () => {
      try {
        const list = await api.get('/unity')
        setListUnity(list.data)
      } catch (err) {

      }
    }
    listUnities()
    setListUnity(false)
  }, [loadListUnity])

  //Load brands
  useEffect(() => {
    const listBrand = async () => {
      try {
        const list = await api.get('/brand')
        setListBrand(list.data)
      } catch (err) {

      }
    }
    listBrand()
    setListBrand(false)
  }, [loadListBrand])

  //Load categories
  useEffect(() => {
    const listCategory = async () => {
      try {
        const list = await api.get('/product/category')
        setListCategory(list.data)
      } catch (err) {

      }
    }
    listCategory()
    setListCategory(false)
  }, [loadListCategory])

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const productTemp = await api.get(`/product/${props.match.params.id}`)
        if (productTemp.status === 200) {
          productLoading(productTemp.data)
          setModEdit(true)
          setOpenLoading(false)
          setLoadRow(false)
        } else {
          setOpenLoading(false)
          setMsgAlert('Não a produto com esse código.')
          setTypeAlert('error')
          setOpenAlert(true)
          setLoadRow(false)
        }
      } catch (err) {
        setOpenLoading(false)
        setMsgAlert('Erro ao carregar produto.')
        setTypeAlert('error')
        setOpenAlert(true)
        setLoadRow(false)
      }
    }
    if (props.match.params.id) {
      setOpenLoading(true)
      loadProduct()
    }
  }, [loadRow])

  const handleUploadProd = async e => {
    setOpenLoading(true)
    try {
      let formData = new FormData()
      formData.append('prod_file', imgProdTemp)
      const imgNameProd = await apiUpload.post('/product/upload/img', formData)
      if (imgNameProd.status === 200) {
        setPrevImgProd(`http://localhost:3333/product/view/img/${imgNameProd.data.fileName}`)
        setImgProd(imgNameProd.data.fileName)
        setConfImgUpload(false)
        setOpenLoading(false)
      } else {
        setOpenLoading(false)
        setMsgAlert('Erro ao enviar imagem para o servidor.')
        setTypeAlert('error')
        setOpenAlert(true)
      }

    } catch (err) {
      setOpenLoading(false)
      setMsgAlert('Erro ao comunicar com o servidor.')
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  const loadSubCategory = async (id) => {
    setCategoryId(id)
    try {
      const listSub = await api.get(`/product/category/sub/list/${id}`)
      if (listSub.status === 200) {
        setListSubCategory(listSub.data)
      }
    } catch (err) {

    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setOpenLoading(true)
    if (props.match.params.id) {
      try {
        const prodTemp = {
          category_id: categoryId,
          sub_category_id: subCategoryId,
          brand_id: brandId,
          bar_code: barCode,
          img_prod: imgProd,
          name: name,
          description: description,
          inventory_movement: inventoryMovement,
          unity_id: unityId
        }
        const prod = await api.put(`/product/${props.match.params.id}`, prodTemp)
        if (prod.status === 200) {
          setOpenLoading(false)
          setMsgAlert('Produto atualizado com sucesso.')
          setTypeAlert('success')
          setOpenAlert(true)
        } else {
          setOpenLoading(false)
          setMsgAlert('Erro ao atualizar produto.')
          setTypeAlert('error')
          setOpenAlert(true)
        }
      } catch (err) {
        setOpenLoading(false)
        setMsgAlert('Erro ao comunicar com o servidor.')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    } else {
      try {
        const prodTemp = {
          category_id: categoryId,
          sub_category_id: subCategoryId,
          brand_id: brandId,
          bar_code: barCode,
          img_prod: imgProd,
          name: name,
          description: description,
          inventory_movement: inventoryMovement,
          unity_id: unityId
        }
        if (unityId === 0) {
          setOpenLoading(false)
          setMsgAlert('Por favor selecionar uma unidade para o produto.')
          setTypeAlert('info')
          setOpenAlert(true)
        } else if (categoryId === 0) {
          setOpenLoading(false)
          setMsgAlert('Por favor selecionar uma categoria para o produto.')
          setTypeAlert('info')
          setOpenAlert(true)
        } else if (subCategoryId === 0) {
          setOpenLoading(false)
          setMsgAlert('Por favor selecionar uma sub-categoria para o produto.')
          setTypeAlert('info')
          setOpenAlert(true)
        } else if (brandId === 0) {
          setOpenLoading(false)
          setMsgAlert('Por favor selecionar uma marca para o produto.')
          setTypeAlert('info')
          setOpenAlert(true)
        } else {
          const prod = await api.post(`/product`, prodTemp)
          if (prod.status === 200) {
            setOpenLoading(false)
            setMsgAlert('Produto adicionado com sucesso.')
            setTypeAlert('success')
            setOpenAlert(true)
            setRedirect(true)
          } else {
            setOpenLoading(false)
            setMsgAlert('Erro ao adicionar produto.')
            setTypeAlert('error')
            setOpenAlert(true)
          }
        }
      } catch (err) {
        setOpenLoading(false)
        setMsgAlert('Erro ao comunicar com o servidor.')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    }
  }

  const classes = FrmProductStyle()

  return (
    <>
      {redirect ? <Redirect to="/product" /> : null}
      <BarMenu />
      <Grid
        container="fluid"
        justify="center"
        className={classes.root} lg={12} xs={12}>

        {openAlert ? <AlertSnack
          open={openAlert}
          type={typeAlert}
          msg={msgAlert}
          setSnack={setOpenAlert} /> : null}

        <Grid item lg={11} xs={11} className={classes.boxFrm}>
          <Grid className={classes.boxHeader}>
            <Typography noWrap={true} variant="h6" component="h6">
              {props.match.params.id ? `Editado Produto` : 'Adicionar Produto'}
            </Typography>
          </Grid>

          <form onSubmit={e => handleSubmit(e)}>

            <Grid item lg={12} xs={12}>

              <Grid
                container="fluid"
                justify="space-around"
                className={classes.frmProd}
                lg={12}
                xs={12}>

                {/** Box upload image */}
                <Grid container="fluid" justify="center" lg={3} xs={12} className={classes.groupInp}>
                  <label htmlFor="contained-button-file">
                    {console.log(prevImgProd)}
                    <img
                      src={prevImgProd ? prevImgProd : noImg}
                      alt="Sem Imagem"
                      className={classes.imgProd} />
                    {
                      !confImgUpload ?
                        '*Clique na imagem para upload (300x300px)'
                        :
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          startIcon={<CloudUploadIcon />}
                          onClick={e => handleUploadProd(e)}
                        >
                          Confirmar Upload
											</Button>
                    }
                  </label>
                  <input
                    accept="image/*"
                    className={classes.inputFile}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={e => {
                      setImgProdTemp(e.target.files[0])
                      setPrevImgProd(URL.createObjectURL(e.target.files[0]))
                      setConfImgUpload(true)
                    }
                    }
                  />

                </Grid>

                {/** Box info basic */}
                <Grid container="fluid" justify="space-between" lg={8} xs={12}>
                  <Grid container="fluid" justify="space-between" lg={12} xs={12}>
                    <Grid item lg={3} xs={12}>
                      <TextField
                        className={classes.inp}
                        required
                        fullWidth
                        variant="outlined"
                        value={barCode}
                        onChange={e => setBarCode(e.target.value)}
                        label="Cód. Barras" />
                    </Grid>
                    <Grid item lg={8} xs={12}>
                      <TextField
                        className={classes.inp}
                        required
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        label="Nome" />
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      className={classes.inp}
                      required
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={6}
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                      label="Descrição" />
                  </Grid>
                  <Grid container="fluid" justify="space-between" lg={12} xs={12}>
                    <Grid item lg={6} xs={12}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel shrink htmlFor="inp-unity">Unidade*</InputLabel>
                        <Select
                          inputProps={{
                            name: "unity",
                            id: "inp-unity"
                          }}
                          label="Unidade"
                          variant="outlined"
                          fullWidth
                          value={unityId}
                          onChange={e => setUnityId(e.target.value)}>
                          {
                            listUnity ?
                              listUnity.map((list, index) => (
                                <MenuItem
                                  key={index}
                                  value={list.id}
                                >
                                  {list.name}
                                </MenuItem>
                              ))
                              :
                              <MenuItem>Não a unidade</MenuItem>
                          }
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item lg={2} xs={12}>
                      <TextField
                        className={classes.inp}
                        required
                        fullWidth
                        variant="outlined"
                        value={amount}
                        label="Qtd Estoque" />
                    </Grid>

                    <Grid item lg={3} xs={12}>

                      <FormControlLabel
                        control={
                          <Switch
                            checked={inventoryMovement}
                            onChange={e => setInventoryMovement(!inventoryMovement)}
                            name="inv-movement"
                            color="primary" />
                        }
                        label="Movimentar Estoque*"
                        labelPlacement="top" />
                    </Grid>
                  </Grid>
                </Grid>

                {/** Box info complement */}
                <Grid container="fluid" justify="space-around" lg={12} xs={12} className={classes.groupInp}>

                  <Grid item lg={5} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel shrink htmlFor="inp-category">Categoria*</InputLabel>
                      <Select
                        inputProps={{
                          name: "category",
                          id: "inp-category"
                        }}
                        label="Categoria"
                        variant="outlined"
                        fullWidth
                        onChange={e => loadSubCategory(e.target.value)}
                        value={categoryId}>
                        {
                          listCategory ?
                            listCategory.map((list, index) => (
                              <MenuItem
                                key={index}
                                value={list.id}
                              >
                                {list.category}
                              </MenuItem>
                            ))
                            :
                            <MenuItem>Não a categoria</MenuItem>
                        }
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={3} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel shrink htmlFor="inp-sub-category">Sub-Categoria*</InputLabel>
                      <Select
                        inputProps={{
                          name: "sub-category",
                          id: "inp-sub-category"
                        }}
                        label="Sub-Categoria"
                        variant="outlined"
                        fullWidth
                        value={subCategoryId}
                        onChange={e => setSubCategoryId(e.target.value)}>
                        {
                          listSubCategory ?
                            listSubCategory.map((list, index) => (
                              <MenuItem
                                key={index}
                                value={list.id}>
                                {list.sub_category}
                              </MenuItem>
                            ))
                            :
                            <MenuItem>Não a sub-categoria</MenuItem>
                        }
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={3} xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel shrink htmlFor="inp-brand">Marcas*</InputLabel>
                      <Select
                        inputProps={{
                          name: 'brand',
                          id: 'inp-brand',
                        }}
                        variant="outlined"
                        label="Marcas"
                        fullWidth
                        value={brandId}
                        onChange={e => setBrandId(e.target.value)}>
                        {
                          listBrand ?
                            listBrand.map((list, index) => (
                              <MenuItem
                                key={index}
                                value={list.id}>
                                {list.brand}
                              </MenuItem>
                            ))
                            :
                            <MenuItem>Não a marcas</MenuItem>
                        }
                      </Select>
                    </FormControl>
                  </Grid>

                </Grid>

                {/** Box btn frm */}
                <Grid container="fluid" justify="space-around" lg={12} xs={12} className={classes.groupBtn}>

                  <Grid item lg={10} xs={12}>
                    <Button
                      className={classes.btnFrm}
                      type="submit"
                      fullWidth
                      startIcon={<Save />}>
                      Salvar
                    </Button>
                  </Grid>

                </Grid>

              </Grid>

            </Grid>

          </form>

        </Grid>

      </Grid>

      <Grid item className={classes.rowMenu} lg={12} xs={12}>
        <ProductFooterBar active={0} />
      </Grid>
      <Loading open={openLoading} />
    </>
  )
}

export default FrmProduct