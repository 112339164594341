import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToApp from '@material-ui/icons/ExitToApp'
import RoomService from '@material-ui/icons/RoomService'
import Event from '@material-ui/icons/Event'
import Hotel from '@material-ui/icons/Hotel'
import Build from '@material-ui/icons/Build'
import ViewList from '@material-ui/icons/ViewList'
import AccountTree from '@material-ui/icons/AccountTree'
import LocalGroceryStore from '@material-ui/icons/LocalGroceryStore'
import ViewModule from '@material-ui/icons/ViewModule'
import barMenuStyles from './style'
import LogoWhite from '../../assets/logoWhite.svg'

const ListItemLink = props => {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const BarMenu = () => {

  const [openMenu, setOpenMenu] = useState(false) 

  const classes = barMenuStyles()

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenMenu(open)
  };

  const listMenu = (props) => (
    <div 
      className={classes.list} 
      role="presentation" 
      onClick={toggleDrawer(false)} 
      onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem button disabled>
          <ListItemIcon> <RoomService /> </ListItemIcon>
          <ListItemText primary="Recepção" />
        </ListItem> 
        <ListItem button disabled>
          <ListItemIcon> <Event /> </ListItemIcon>
          <ListItemText primary="Reservas" />
        </ListItem> 
        <ListItem button disabled>
          <ListItemIcon> <Hotel /> </ListItemIcon>
          <ListItemText primary="Governança" />
        </ListItem> 

        <ListItemLink to="/maintenance" primary="Manutenção" icon={<Build />} />

        <Divider />

        <ListItemLink to="/product" primary="Produtos/Serviços" icon={<ViewList />} />
        <ListItemLink to="/shopping" primary="Compras" icon={<LocalGroceryStore />} />
        <ListItemLink to="/stock" primary="Estoque" icon={<ViewModule />} />

        <Divider />

        <ListItemLink 
          to="/departaments" 
          primary="Departamentos" 
          icon={<AccountTree />} />

        <ListItem button>
          <ListItemIcon> <MenuIcon /> </ListItemIcon>
          <ListItemText primary="UHs" />
        </ListItem>

      </List>
    </div>
  )

  return (
    <>
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
          <IconButton 
            edge="start" 
            className={classes.menuButton} 
            color="inherit" 
            aria-label="menu"
            onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
            <Typography variant="h6" className={classes.title}>
              <img 
                src={LogoWhite} 
                className={classes.img} 
                alt="Hotel Santo Graal"
                onClick={toggleDrawer(true)}/>
            </Typography>
            <IconButton 
              edge="start" 
              className={classes.menuButton} 
              color="inherit" 
              aria-label="menu">
              <ExitToApp />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer 
        anchor='left' 
        open={openMenu}
        onClose={toggleDrawer(false)}>
        {listMenu()}
      </Drawer>
    </>
  )
}

export default BarMenu

