import React, { useState } from 'react'
import BarMenu from '../BarMenu'
import Grid from '@material-ui/core/Grid'


const Main = () => {
  return (
    <Grid container="fluid">
      <Grid item lg={12} xs={12}>
        <BarMenu />
      </Grid>
      <Grid item lg={12} xs={12}>
      </Grid>
    </Grid>
  )
}

export default Main