import { makeStyles } from '@material-ui/core/styles'

const loginStyles = makeStyles((themes) => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    background: 'rgba(34, 54, 15, 0.8)'
  },
  paper: {
    padding: '10px',
    textAlign: 'center',
    color: '#22360f',
  },
  header: {
    margin: '0.2rem 2rem',
    textAlign: 'center',
    borderBottom: '1px solid rgba(34, 54, 15, 0.5)',
    '& img': {
      width: '180px',
      paddingTop: '0.3rem'
    }
  },
  frm: {
    margin: '0rem 3rem 0rem 3rem',
  },
  groupInp: {
    marginTop: '1rem',
  },
  inp: {
    borderColor: '#22360f',
  },
  groupBtn: {
    marginTop: themes.spacing(2)
  },
  button: {
    backgroundColor: '#22360f',
    margin: themes.spacing(1),
    padding: themes.spacing(2),
    '&:hover': {
      backgroundColor: '#334c1a',
    }
  },
  backdrop: {
    zIndex: themes.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export default loginStyles