import { makeStyles } from '@material-ui/core/styles'

const barMenuStyles = makeStyles((themes) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: themes.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    background: '#22360f',
    boxShadow: 'none',
  },
  img: {
    width: '165px',
    marginTop: '5px',
    cursor: 'pointer',
  },
  list: {
    width: '250px',
  },
  fullList: {
    width: 'auto',
  },
}))

export default barMenuStyles