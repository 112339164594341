import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import api from "../../services/api"
import { login, isAuthenticated } from "../../services/auth"
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Send from '@material-ui/icons/Send'
import VpnKey from '@material-ui/icons/VpnKey'
import Logo from '../../assets/logo.svg'
import loginStyles from './style.js'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Login = () => {
  
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false)
  const [typeAlert, setTypeAlert] = useState('error')
  const [msgAlert, setMsgAlert] = useState('Falha ao comunicar com o servidor.')
  const [redirect, setRedirect] = useState(isAuthenticated())
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  };

  const handleSubmit = async e => {
    e.preventDefault()
    setOpen(true)
    try {
      await api.post("/auth", {email, password})
      .then(response => {        
        login(response.data.token)
        setRedirect(true)
      }) 
      .catch(response => {
        setTypeAlert('warning')
        setMsgAlert('Usuario sem autorização de acesso.')
        setOpen(false)
        setAlert(true)
      })
    } catch (erro) {
      setTypeAlert('error')
      setMsgAlert('Falha ao comunicar com o servidor.')
      setOpen(false)
      setAlert(true)
    }
  }
  
  const classes = loginStyles()

  return (
    <>
    { 
      alert ? 
        <Snackbar 
          open={alert} 
          autoHideDuration={5000} 
          onClose={handleClose} 
          anchorOrigin={{vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity={typeAlert}>
            {msgAlert}
          </Alert>
        </Snackbar>
        :
        ''
    }
    <Grid 
      container="fluid" 
      justify="center"
      alignItems="center"
      className={classes.root}>
      { redirect ? <Redirect to="/app"/> : '' }
      <Grid item lg={4} xs={10}>
        <Paper className={classes.paper}>
          <Typography className={classes.header} component="h2">
            <img src={Logo} alt="Hotel Santo Graal" />
          </Typography>

          <form
            className={classes.frm}
            onSubmit={e => handleSubmit(e)}
          >
            <div className={classes.groupInp}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid lg={1} xs={1} item>
                  <AccountCircle />
                </Grid>
                <Grid lg={11} xs={11} item>
                  <TextField 
                    required
                    type="text"
                    label="E-mail"
                    fullWidth
                    onChange={e => setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.groupInp}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid lg={1} xs={1} item>
                  <VpnKey />
                </Grid>
                <Grid lg={11} xs={11} item>
                  <TextField 
                    required
                    type="password"
                    label="Senha"
                    fullWidth
                    onChange={e => setPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.groupBtn}>
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<Send />}
              >
                Entrar
              </Button>
            </div>
          </form>
        </Paper>
      </Grid>
    </Grid>
    <Backdrop 
      className={classes.backdrop} 
      open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
    </>
  )
}

export default Login