import { makeStyles } from '@material-ui/core/styles'

const UnityBoardStyle = makeStyles((themes) => ({
  root: {
    flexGrow: 1,
  },
  boxFrm: {
    padding: '10px',
    marginBottom: '8px',
    border: '1px solid rgba(51, 76, 26, 0.9)',
    borderRadius: '5px',
    '& > *': {
      padding: themes.spacing(1),
    }
  },
  inpWhite: {
  },
  btnFrm: {
    height: '100%',
    color: '#f2f2f2',
    background: 'rgba(34, 51, 15, 1)',
    letterSpacing: '1px',
    '&:hover': {
      color: 'rgba(34, 51, 15, 1)',
      background: 'none',
      border: '1px solid rgba(34, 51, 15, 0.8)'
    }
  },
  rowMain: {
    minHeight: 'calc(100vh-65px)',
    padding: '1rem 0.5rem',
    marginBottom: '4rem',
  },
  boxHeader: {
    background: 'rgba(51, 76, 26, 0.9)',
    borderRadius: '5px 5px 0px 0px',
    padding: '10px',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    '& h6': {
      letterSpacing: '1.5px',
    }
  },
  btnHeader: {
    textAlign: 'right',
    '& > *': {
      marginRight: '1rem',
      '& > *': {
        color: '#fff',
      }
    }
  },
  listItem: {
  },
  listRow: {
    color: 'rgba(51, 76, 26, 1)',
    fontWeight: 800,
    justify: 'center',
    alignItems: 'center',
    borderBottom: '1px solid rgba(51,76,26,0.8)',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(51,76,26,0.3)',
    },
    '& div': {
      padding: '8px',
    }
  },
  boxAction: {
    alignItems: 'space-between',
    '& > *': {
      marginLeft: themes.spacing(1)
    }
  },
  btnEye: {
    color: 'rgba(51, 76, 26, 1)'
  },
  btnEdit: {
    color: 'rgba(51, 76, 26, 1)'
  },
  btnDelete: {
    color: 'rgba(51, 76, 26, 1)'
  },
  rowMenu: {
    position: 'fixed',
    bottom: 0,
  },
}))

export default UnityBoardStyle